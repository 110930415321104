<template>
    <div>
        <v-app-bar app class="navbar elevation-0" style="left: 48px;" height="56.5">
            <v-spacer></v-spacer>
            <v-badge color="transparent" class="primary--text" :offset-x="(getRemainingDays()).toString().length == 2 ? 51 : 46" :offset-y="20" overlap>
                <template v-slot:badge>
                    <span :style="`color: ${daysRemainColor()}; font-weight: 700;`"> {{ getRemainingDays() }} </span>
                </template>
                
                <v-btn title="Subscription Days" fab small style="padding: 22px !important;" class="me-0" icon @click="days_remain_dialog = true">
                    <v-icon size="24"> mdi-update </v-icon>
                </v-btn>
            </v-badge>
            <v-btn title="Change Theme" fab small style="padding: 22px !important;" class="me-0 myClass" icon @click="changeTheme()">
                <v-icon size="24"> mdi-theme-light-dark </v-icon>
            </v-btn>
            <v-btn title="Notifications" fab small style="padding: 22px !important; margin-inline-end: 10px;" icon to="/notifications">
                <v-icon size="24">far fa-bell</v-icon>
            </v-btn>
            
            <v-menu offset-y open-on-click>
                <template v-slot:activator="{ on, attrs }">
                    <span :style="`margin-start: 5px; padding-inline-start: 6px; padding-inline-end: 8px; margin-inline-end: -9.5px; font-size: 10pt; ${$vuetify.theme.dark ? '' : 'font-weight: 500;'}`"> {{ user.sub_user_name || user.user_name }}  </span>
                    <div style="border-radius: 50px; background: linear-gradient(90deg, rgba(0,207,222,1) 0%, rgba(112,83,240,1) 90%); padding: 2px;">
                        <v-avatar size="38px" v-on="on" v-bind="attrs" >
                            <v-img  alt="Avatar" :src="
                                (user.sub_user_id && user.sub_user_profile_image) ? `${$image_url}lab/image/profile/${user.sub_user_profile_image}`
                                : (user.sub_user_id && !user.sub_user_profile_image) ? default_profile_image
                                : user.user_profile_image ? `${$image_url}lab/image/profile/${user.user_profile_image}` 
                                : default_profile_image">
                            </v-img>
                        </v-avatar>
                    </div>
                </template>
                <v-list height="114" class="py-0 mt-1">
                    <input type="file" ref="uploadImage" style="display: none;" @change="processFileProfileImage">
                    <v-list-item @click="openUploadProfileImage()" class="py-0" style="height: 38px; min-height: 0;">
                        <v-list-item-icon class="mt-3">
                            <v-icon color="primary" size="16">far fa-image</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="ms-n6" :style="`${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`">Upload Picture</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="delete_dialog_profile_image = true" class="py-0" style="height: 38px; min-height: 0;">
                        <v-list-item-icon class="mt-3">
                            <v-icon color="primary" size="16">fas fa-trash</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="ms-n6" :style="`${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`">Delete Picture</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout" class="py-0" style="height: 38px; min-height: 0;">
                        <v-list-item-icon class="mt-3">
                            <v-icon color="primary" size="16">fas fa-sign-out</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="ms-n6" :style="`${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`"> Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        
        <v-navigation-drawer :mini-variant.sync="mini" permanent v-model="drawer" app :right="$store.getters.language.rtl" class="pa-0 elevation-0" width="210" >
            <v-list>
                <v-list-item >
                    <v-list-item-icon style="margin: auto -0.4rem;">
                        <v-avatar tile size="32" id="avatar_logo">
                            <img @click="mini = !mini" @dblclick="$router.push('/')" :src="logoMain" alt="">
                        </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-title>
                        <h1 id="logo-title"> Xera Clinic </h1>
                    </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item v-for="(item , index) in filtered_navbar_links" :key="index" :title="item.title.en" link :to="item.url" @click.stop="mini = true">
                    <v-list-item-icon class="item-icon">
                        <v-icon :size="item.size">{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="item-title">{{item.title.en }}</v-list-item-title>
                </v-list-item> -->
                <v-list-item v-for="(item , index) in filtered_navbar_links.filter(obj => !store_page_codes.includes(obj.code))" :key="index" :title="item.title.en" link :to="item.url" @click.stop="mini = true">
                    <v-list-item-icon class="item-icon">
                        <v-icon :size="item.size">{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="item-title" :style="`${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`">{{item.title.en }}</v-list-item-title>
                </v-list-item>

                <!-- TODO: Please remove 'to' property and 'mini = true' when lab store is completed or in development and add show_store_group = !show_store_group;-->
                <v-list-item to="/lab-store" @click.stop="mini = true; ">
                    <v-list-item-icon class="item-icon" >
                        <v-icon :color="`${show_store_group ? 'primary' : ''}`" size="19"> fas fa-shop </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title :style="`margin-inline-start: -3px ; padding-top: 5px;color: ${show_store_group ? '#7053f7 ' : ''}; ${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`">
                            <span> Store  </span>
                            <!-- TODO: Please uncomment this when lab store is compeleted or in development -->
                            <!-- <span class="float-end" style="margin-top: -5.5px;">
                                <v-icon :color="show_store_group ? 'primary' : ''" v-if="!show_store_group"> mdi-chevron-down </v-icon>
                                <v-icon :color="show_store_group ? 'primary' : ''" v-else> mdi-chevron-up </v-icon> 
                            </span> -->
                       
                    </v-list-item-title>
                </v-list-item>
                
                <!-- TODO: Please uncomment this when lab store is compeleted or in development -->
                <!-- <transition name="slide">
                    <div v-if="show_store_group">
                        <v-list-item v-for="(item , index) in filtered_navbar_links.filter(obj => store_page_codes.includes(obj.code))" :key="index" :title="item.title.en" link :to="item.url" @click.stop="mini = true; show_store_group=false;">
                            <v-list-item-icon class="item-icon">
                                <v-icon :size="item.size">{{item.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="item-title">{{item.title.en }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </transition> -->
            </v-list>


            <template v-slot:append >
               <v-list-item title="Setting" link to="/setting" @click.stop="mini = true" :class="{'mb-n3': !mini}" v-if="$store.getters.auth.role_type == 'admin' || $store.getters.permissions.includes('1044')">
                    <v-list-item-icon class="item-icon">
                        <v-icon :size="24"> mdi-cog </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="item-title" :style="`font-size: 8.75pt; ${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`"> Setting </v-list-item-title>
                </v-list-item>
                <div  v-if="show_copyright" class="primary--text text-center animate__animated animate__fadeIn" style="font-size: 12px; font-weight: 300; align-items: center; display: grid; height: 48px;">
                    <v-divider style="margin-bottom: 1px;"></v-divider>
                    <span :style="`margin-top: -13px; ${$vuetify.theme.dark ? '' : 'font-weight: 500;'}`"> <span style="font-size: 14px;"> &copy; </span> {{ new Date().getFullYear() }}&nbsp;&nbsp;Xera Clinic&nbsp;&nbsp;|&nbsp;&nbsp;v 1.0.0 </span>
                </div>
                
            </template>
        </v-navigation-drawer>

        <v-dialog v-model="delete_dialog_profile_image" persistent max-width="410">
            <Delete-Dialog :text="`Doy you want to delete your profile picture ?`" @cancel-action="delete_dialog_profile_image = false" @delete-action="delete_profile_image()" />
        </v-dialog>

        <v-dialog v-model="light_mode_comigsoon_dialog" max-width="410">
            <Comingsoon-Dialog title="Light Mode" />
        </v-dialog>

        <v-dialog v-model="days_remain_dialog" max-width="640">
            <v-card >
                <v-card-text class="pa-4 pb-6" :style="`height: 10.3cm; background: ${$vuetify.theme.dark ? '#28293d' : '#FFFFFF'};`">
                    <v-card>
                        <v-card-title style="height: 1.52cm; padding-top: 14px;">
                            <span style="font-size: 1.25rem;"> Subscription </span>
                        </v-card-title>
                    </v-card>
                    
                    <v-layout row wrap mt-3>
                        <v-flex xs12 lg6 xl6 md6 sm12 class="text-center my-1 ps-3 pe-2">
                            <v-card style="padding-block: 0.54cm; display: flex; align-items: center; justify-content: center;" >
                                <v-progress-circular style="margin-top: 6px; margin-inline-start: -2px;" v-if="($store.getters.lab.lab_remaining_days || 0) > 7" id="progress-remain-primary" :rotate="270" :size="230" :width="18" :value="calculateRemainingProgress()" color="#7053F7" >
                                    <span style="margin-top: -21px;">
                                        <p style="color: rgb(163, 163, 163); font-size: 18pt;"> Days Left </p>
                                        
                                        <p :style="`color: ${$vuetify.theme.dark ? 'white' : '#262626'}; font-size: 40pt; margin-top: 34px;`"> {{ getRemainingDays() }} </p>  
                                    </span>
                                </v-progress-circular>
                                <v-progress-circular style="margin-top: 6px; margin-inline-start: -2px;" v-else-if="($store.getters.lab.lab_remaining_days || 0) <= 7 && ($store.getters.lab.lab_remaining_days || 0) > 0" id="progress-remain-warning" :rotate="270" :size="230" :width="18" :value="calculateRemainingProgress()" color="#E5B800" >
                                    <span style="margin-top: -21px;">
                                        <p style="color: rgb(163, 163, 163); font-size: 18pt;"> Days Left </p>
                                        
                                        <p :style="`color: ${$vuetify.theme.dark ? 'white' : '#262626'}; font-size: 40pt; margin-top: 34px;`"> {{ getRemainingDays() }} </p>  
                                    </span>
                                </v-progress-circular>
                                <v-progress-circular style="margin-top: 6px; margin-inline-start: -2px;" v-else id="progress-remain-error" :rotate="270" :size="230" :width="18" :value="calculateRemainingProgress()" color="#E53535" >
                                    <span style="margin-top: -21px;">
                                        <p style="color: rgb(163, 163, 163); font-size: 18pt;"> Days Pass </p>
                                        
                                        <p :style="`color: ${$vuetify.theme.dark ? 'white' : '#262626'}; font-size: 40pt; margin-top: 34px;`"> {{ getRemainingDays() }} </p>  
                                    </span>
                                </v-progress-circular>
                            </v-card>
                        </v-flex>
                        
                        <v-flex xs12 lg6 xl6 md6 sm12 class="text-center my-1 pe-3 ps-2">
                            <v-card :height="276">
                                <v-card-title style="height: 56.5px; padding-block: 6px;">
                                    <span style="font-size: 1.15rem;"> Payment Method </span>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text style="padding-inline: 0.60cm; padding-top: 0;">
                                    <v-btn color="#56B89D" class="payment-btn" block style="margin-top: 1.51cm;"> FIB Account </v-btn>
                                    <v-btn color="#FF4081" class="payment-btn" block style="margin-top: 1.38cm;"> FastPay Account </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                    
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import 'animate.css';
    import navbar_links from '../store/navbar-links.json'
    import axios from 'axios'
    export default {
        data: function () {
            return {
                drawer: null,
                logoMain: require('./../assets/main-logo.svg'),
                default_profile_image: require('./../assets/user.png'),
                mini: true,
                group2_value: false,
                profile_image_file: null,
                delete_dialog_profile_image: false,
                navbar_links,
                store_page_codes: ['1014', '1015', '1016', '1017', '1018', '1019', '1020', '1026'],
                show_store_group: false,
                light_mode_comigsoon_dialog: false,
                show_copyright: false,
                days_remain_dialog: false,
            }
        },
        mounted() {
            this.$store.state.mini_drawer = this.mini
        },
        computed: {
            languages() {
                return this.$store.getters.languages
            },
            user() {
                return this.$store.getters.auth
            },
            filtered_navbar_links() {
                if(this.$store.getters.auth.role_type == 'admin') {
                    return this.navbar_links.filter(obj => obj.show);
                } else {
                    return this.navbar_links.filter(obj => obj.show && this.$store.getters.permissions.includes(obj.code))
                }
            },
            current_height() {
                return document.body.clientHeight - 200;
            }
        },
        methods: {
            openUploadProfileImage() {
                this.$refs.uploadImage.click();
            },
            processFileProfileImage(event) {
                this.profile_image_file = event.target.files[0];
                this.update_profile_image();
            },
            logout() {
                this.$store.dispatch('logout');
                this.$router.push('/login');
            },
            update_profile_image() {
                let formData = new FormData();
                formData.append('image_file', this.profile_image_file);
                if(this.user.sub_user_id) {
                    axios.post('/sub_users/update_profile_image/' + this.user.sub_user_id, formData).then(r => {
                        this.$store.state.auth.sub_user_profile_image = r.data.sub_user_profile_image;
                    }).catch(e => {
                        alert('Failed to upload image')
                    })
                } else if(this.user.user_id) {
                    axios.post('/users/update_profile_image/' + this.user.user_id, formData).then(r => {
                        this.$store.state.auth.user_profile_image = r.data.user_profile_image;
                    }).catch(e => {
                        alert('Failed to upload image')
                    })
                }
            },
            delete_profile_image() {
                if(this.user.sub_user_id) {
                    axios.delete('/sub_users/delete_profile_image/' + this.user.sub_user_id).then(r => {
                        this.$store.state.auth.sub_user_profile_image = null;
                        this.delete_dialog_profile_image = false;
                    }).catch(e => {
                        alert('Failed to delete image')
                    })
                } else if(this.user.user_id) {
                    axios.delete('/users/delete_profile_image/' + this.user.user_id).then(r => {
                        this.$store.state.auth.user_profile_image = null;
                        this.delete_dialog_profile_image = false;
                    }).catch(e => {
                        alert('Failed to delete image')
                    })
                } 
            },
            changeTheme() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            },
            daysRemainColor() {
                const days = this.$store.getters.lab.lab_remaining_days
                if(days > 7) {
                    return '#7053F7'
                } else if(days <= 7 && days > 0) {
                    return '#FFCC00'
                } else {
                    return '#FF3B3B'
                }
            },
            getRemainingDays() {
                const days = this.$store.getters.lab.lab_remaining_days || 0
                return days < 0 ? days * (-1) : days
            },
            calculateRemainingProgress() {
                const days = this.$store.getters.lab.lab_remaining_days || 0
                const period = this.$store.getters.lab.lab_subscription_period || 0
                if(days >= 0) {
                    return (days / period) * 100
                } else {
                    return ((days * (-1)) / 7) * 100
                }
            },
            systemUpdate() {
                window.location.reload(true);
            },
            toggleFullScreen() {
                if (document.fullscreenElement || document.webkitFullscreenElement ||
                    document.mozFullScreenElement || document.msFullscreenElement) {
                    this.exitFullscreen();
                } else {
                    this.enterFullscreen();
                }
            },
            enterFullscreen() {
                var elem = document.documentElement;

                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
                localStorage.setItem("fullscreenState", true);
            },

            exitFullscreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                localStorage.setItem("fullscreenState", false);
            }
            // goToHome() {
            //     if(this.$store.getters.auth.role_type == 'admin' || this.$store.getters.permissions.includes('1034')) {
            //         this.$router.push('/dashoard')
            //     } else {
            //         this.$router.push('/lab_samples')
            //     }
            // }
        },
        watch: {
            mini(value) {
                this.show_copyright = !value
                this.$store.state.mini_drawer = value
            }
        }
    }
</script>

<style scoped>
    .v-list-item {
        padding: 0 10px !important;
    }
    
</style>