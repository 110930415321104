<template>
    <v-app>
        <div>
            <v-main>
                <router-view />
            </v-main>
        </div>
    </v-app>
</template>

<script>
    import {
        getAuth
    } from 'firebase/auth'
    import APPNavbar from './components/Navbar.vue'
    import APPLoading from './components/Loading.vue'
    import DisabledPage from './components/Disabled-Page.vue'
    import axios from 'axios'
    export default {
        components: {
            APPNavbar,
            APPLoading,
            DisabledPage
        },
        name: 'App',

        data: () => ({
            auth: true,
            loading: true,
        }),
        mounted() {
            
        },
        watch: {
            
            
        }
    };
</script>
<style lang="scss">
    @import url('https://rsms.me/inter/inter.css');
    @import url('./assets/style.css');
    @import url('./assets/light-theme.css');

    // .v-main__wrap {
    //     justify-content: center ;
    //     display: flex;
    // }

    @media print {
        .no-print {
            display: none !important;
        }
        .only-print {
            display: block !important;
        }
        .overlay-print {
            opacity: 1 !important;
        }
        .invoice-header {
            display: block !important;
        }
        .only-print-table-row {
            display: table-row !important;
        }
        .th-header-title {
            background-color: rgb(33, 95, 209) !important;
            border-color: rgb(33, 95, 209) !important;
            color: white;
            -webkit-print-color-adjust: exact; 
        }
        .td-body-index {
            background-color: rgba(238, 234, 11, 0.863) !important;
            color: black;
            -webkit-print-color-adjust: exact; 
        }
        .page-break {
            page-break-after: always !important;
        }
        .v-main {
            padding: 0 !important;
        }
        #main-router-view {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        
    }
    .overlay-print {
        opacity: 0;
    }
    .only-print {
        display: none;
    }
    .invoice-header {
        display: none;
    }
    .only-print-table-row {
        display: none;
    }

    .no-border {
        border: none !important;
    }
    

    *:not(i) {
        font-family: 'Inter', sans-serif;
    }

    .navbar,
    .v-navigation-drawer {
        min-width: 70px;
    }
    *::-webkit-scrollbar {
        width: 0.35vw;
        height: 0.75vh;
    }
    *::-webkit-scrollbar-corner {
        display: none !important;
    }
    .v-navigation-drawer__content {
        overflow: hidden;
    }
    .v-navigation-drawer__content:hover {
        overflow-y: auto;
    }

    *::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #7053f7;
        // border-inline: 0.20vw solid;
        border-block: 0.31vh solid;
        border-color: #7053f7 ; 
        
    }
   
    *::-webkit-scrollbar-thumb:hover {
        // border-inline: 0.22vw solid #302959;
        background-color: #4F2CF5;
        border-color: #302959;
        border-block: none;
    }


    *::-webkit-scrollbar-track {
        border-radius: 4px;
        // background-color: #302959;
        // display: none;
        margin-block: 1px;
    }


    *::-webkit-scrollbar-track:hover {
        background-color: #c3bfdf;
        display: inherit;
    }
   
    .sample-tests-table-print-preview::-webkit-scrollbar-track:hover {
        background-color: #AB9BFB;
    }
    

    // Scroll bar in text area
    .v-textarea *::-webkit-scrollbar-track {
        background-color: #302959;
        display: inherit;
    }
    .theme--light .v-textarea *::-webkit-scrollbar-track {
        background-color: #c3bfdf;
        display: inherit;
    }


    .custom-scroll textarea::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

   

    .custom-scroll textarea::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color:  #7053F7;
    }

    
    // body::-webkit-scrollbar{
    //     display: none;
    // }


</style>