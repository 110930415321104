<template>
    <div>
        <v-menu offset-y open-on-click top absolute nudge-left="20" nudge-top="13">
            <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs" class="pagination-activator" :style="`display: inline-grid; justify-content: center; border-radius: 6px; font-size: ${fontSize};`"> 
                    {{ footer.props.pagination.itemsPerPage == -1 ? 'All' : footer.props.pagination.itemsPerPage  }}
                </span>
            </template>

            <v-list height="193" class="py-0" elevation="0" :style="`background-color: ${$vuetify.theme.dark ? '#28293d' : '#FFFFFF'};`">
                <v-list-item class="py-0" style="height: 38px; min-height: 0;" >
                    <v-list-item-title :style="`cursor: pointer; ${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`" @click="footer.props.pagination.itemsPerPage = -1; $emit('updateItemsPerPage', -1);">All</v-list-item-title>
                </v-list-item>
                <v-list-item class="py-0" style="height: 38px; min-height: 0;">
                    <v-list-item-title :style="`cursor: pointer; ${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`" @click="footer.props.pagination.itemsPerPage = 300; $emit('updateItemsPerPage', 300);">300</v-list-item-title>
                </v-list-item>
                <v-list-item class="py-0" style="height: 38px; min-height: 0;">
                    <v-list-item-title :style="`cursor: pointer; ${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`" @click="footer.props.pagination.itemsPerPage = 200; $emit('updateItemsPerPage', 200);">200</v-list-item-title>
                </v-list-item>
                <v-list-item class="py-0" style="height: 38px; min-height: 0;">
                    <v-list-item-title :style="`cursor: pointer; ${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`" @click="footer.props.pagination.itemsPerPage = 100; $emit('updateItemsPerPage', 100);">100</v-list-item-title>
                </v-list-item>
                <v-list-item class="py-0" style="height: 38px; min-height: 0;">
                    <v-list-item-title :style="`cursor: pointer; ${$vuetify.theme.dark ? '' : 'font-weight: 500; color: #262626;'}`" @click="footer.props.pagination.itemsPerPage = 50; $emit('updateItemsPerPage', 50);">50</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-btn style="width: 20px; height: 20px;" @click="$emit('prev-page')" icon color="primary" :disabled="rows.length == 0 || current_page <= 1"> <v-icon> mdi-chevron-left </v-icon> </v-btn>

        <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
                <span class="mx-1" v-on="on" v-bind="attrs" :style="`font-size: ${fontSize};`">{{ footer.props.pagination.page }}-{{ footer.props.pagination.pageCount }}</span>
            </template>
            <span> Pages: <span> {{ footer.props.pagination.page }} <span> - {{ footer.props.pagination.pageCount }} </span> </span>  </span>
        </v-tooltip>
        
        <v-btn style="width: 20px; height: 20px;" @click="$emit('next-page')" icon color="primary" :disabled="rows.length == 0 || current_page == footer.props.pagination.pageCount"> <v-icon> mdi-chevron-right </v-icon> </v-btn>
                
    </div>
</template>

<script>
export default {
    props: ['footer', 'rows', 'current_page', 'fontSize'],
    watch: {
        'footer.props.pagination.itemsPerPage': function() {
            if(this.current_page > this.footer.props.pagination.pageCount) {
                this.$emit('updateCurrentPage', this.footer.props.pagination.pageCount)
            }
        }
    }
}
</script>
