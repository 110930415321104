export default  {
    validatePhoneNumber(evt) {
        var theEvent = evt || window.event;

        // Handle paste
        if (theEvent.type === 'paste') {
            key = theEvent.clipboardData.getData('text/plain');
        } else {
        // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\r|\n/;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    },
    validateNumber(evt) {
        var theEvent = evt || window.event;

        // Handle paste
        if (theEvent.type === 'paste') {
            key = theEvent.clipboardData.getData('text/plain');
        } else {
        // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\.|\r|\n/;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    },
    validateString(evt) {
        var theEvent = evt || window.event;

        // Handle paste
        if (theEvent.type === 'paste') {
            key = theEvent.clipboardData.getData('text/plain');
        } else {
        // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /^[A-Za-z\s.]*$/;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    },
    getCurrentISODate() {
        var moment = require('moment-timezone');
        var now = moment();
        var timeZone = 'Asia/Baghdad'; 
        var adjusted = now.tz(timeZone);
        adjusted.add(3, 'hours');
        
        return adjusted.toISOString();
    },
    extractNumberFromString(str) {
        const regex = /[><=]*\d+(\.\d+)?/g;
        const matches = str.match(regex);
        
        if (matches && matches.length > 0) {
          const numberString = matches[0].replace(/[><=]/g, '');
          return parseFloat(numberString);
        }
        
        return str; // or any default value you prefer
    },
    capitalizeFirstCharacter(string) {
        if (string && string.length > 0) {
          var firstCharacter = string.charAt(0);
          var capitalizedCharacter = firstCharacter.toUpperCase();
          var result = capitalizedCharacter + ((string || '').slice(1)).toLowerCase();
          return result;
        } else {
          return string;
        }
    },
    isEmpty(value) {
        return value === null || value === "" || value === undefined
    }
}