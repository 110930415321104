import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VDataFooter from 'vuetify/lib/components/VDataIterator/VDataFooter'
import VDataHeader from 'vuetify/lib/components/VDataTable/VDataTableHeader'
import VDataTable from 'vuetify/lib/components/VDataTable/VDataTable'
import { VAutocomplete } from 'vuetify/lib';

VDataFooter.options.props.nextIcon.default = 'mdi-arrow-right mdi-18px';
VDataFooter.options.props.prevIcon.default = 'mdi-arrow-left mdi-18px';
VDataHeader.options.props.sortIcon.default = null
VDataTable.options.props.noDataText.default = ''

VAutocomplete.options.props.noDataText.default = ''
// VDataFooter.options.props.pageText.default = 'Row: \xa0\xa0\xa0\xa0{1} \xa0\xa0of \xa0\xa0{2}'
// VDataFooter.options.props.itemsPerPageOptions.default = () => [100, 200, 300, -1];

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi' || 'fa',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        all: '#9a84fa',
        error: '#FF3B3B',
        primary: '#7053F7',
        success: '#06C270',
        request: '#9DBFF9',
        waiting: '#FDED72',
        post_waiting: '#FDDD48',
        verified: '#73DFE7',
        partial_verified: '#70eba1',
        done: '#39D98A',
        print: '#9a84fa',
        canceled: '#FF8080',
        tube_state: "#ffbe69",
        customDark:'#FFFFFF',
      },
      dark: {
        all: '#9a84fa',
        error: '#FF3B3B',
        primary: '#7053F7',
        success: '#06C270',
        customDark:'#28293D',
        backgroundDark: '#201F32',
        customErrorMsg: '#FF3B3B',
        request: '#9DBFF9',
        waiting: '#FDED72',
        post_waiting: '#FDDD48',
        verified: '#73DFE7',
        partial_verified: '#70eba1',
        done: '#39D98A',
        print: '#9a84fa',
        canceled: '#FF8080',
        tube_state: "#ffbe69"
      },
    },
  },

});