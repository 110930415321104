<template>
    <v-snackbar v-model="$store.state.snackbar.value" :timeout="3000" v-if="!printing">
        <v-layout row wrap>
            <v-flex xs1 class="ps-2 ">
                <v-avatar tile size="32" id="avatar_logo" class="float-start">
                    <img  :src="logoMain" alt="" >
                </v-avatar>
            </v-flex>
            <v-flex xs11 align-self-center>
                <span  :style="`color: ${$store.state.snackbar.color};`"> {{$store.state.snackbar.text}} </span>
            </v-flex>
        </v-layout>
        <template v-slot:action="{ attrs }">
            <v-btn icon color="error" v-bind="attrs" small @click="$store.state.snackbar.value = false"> <v-icon> mdi-close </v-icon> </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            logoMain: require('./../assets/main-logo.svg'),
        }
    },
    computed: {
        printing() {
            return this.$store.state.printing_a4 || this.$store.state.printing_mini || this.$store.state.printing_a4_monthly_payment || this.$store.state.printing_a4_sample_no_result
        }
    }
}
</script>
