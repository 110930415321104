import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)
const routes = [
    {
        path: '/lab_samples/:uuid',
        name: 'PrintLab_samples',
        component: () => import('../views/Lab_samples/Print.vue'),
        meta:{
            title : 'Lab_samples Print',
        }
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
        meta:{
            title : 'Not found',
        }
    },
    
]
const router = new VueRouter({
    routes,
    mode:'history'
})


export default router