<template>
    <v-card>
        <v-card-title class="justify-center py-0">
            <img :src="logoMain" alt="" style="height: 50px; width: 50px; display: block; margin-top: 0.54cm;">
        </v-card-title>
        <v-card-title class="justify-center py-0" style="margin-top: 0.5cm;">
            <div style="font-weight: bold; font-size: 13pt;">
                {{ title }}
            </div>
        </v-card-title>
        <v-card-text class="text-center">
            <p :class="{'white--text': $vuetify.theme.dark, 'black--text': !$vuetify.theme.dark}" style="font-size: 10pt; margin-bottom: 16px; margin-top: 0.45cm;"> Coming Soon </p>
        </v-card-text>
        
    </v-card>
</template>

<script>
export default {
    props: ['title'],
    data() {
        return {
            logoMain: require('./../assets/main-logo.svg'),
        }
    }
}
</script>

